window.__WITHIN_EMBER_APP__ = true;
window.embeddedReactApps = window.embeddedReactApps || {};

window.sendActionToEmber = function(appName, type, payload) {
  try {
    window.embeddedReactApps[appName].performEmberAction(type, payload);
  } catch (e) {
    console.error('You tried to fire an ember action on a non-existent react app: ', appName);
  }
};

window.sendMessageToReact = function(appName, type, payload) {
  const reactApp = window.embeddedReactApps[appName] || {};
  if (!reactApp.isAppStarted) {
    const queuedMessages = reactApp.queuedMessages || [];
    queuedMessages.push({ type, payload });
    reactApp.queuedMessages = queuedMessages;
  } else {
    try {
      const handlers = reactApp.registeredReactMessages[type];
      if (!handlers) {
        console.error(`No React action of type ${type} was registered on app: ${appName}`);
      } else {
        return Promise.all(handlers.map(handler => handler(payload)));
      }
    } catch (e) {
      console.error(`Tried to send action to react app: ${appName} that is not loaded.`);
    }
  }
};

window.registerReactMessageHandler = function(appName, type, handler) {
  const handlers = window.embeddedReactApps[appName].registeredReactMessages[type] || [];
  handlers.push(handler);
  window.embeddedReactApps[appName].registeredReactMessages[type] = handlers;
};
